.modal {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1040;
  width: 100vw;
  height: 100vh;
  background-color: $color-black;
  opacity: 0.8;

  &__wrapper {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 1050;
    width: 100%;
    height: 100%;
    overflow-x: hidden;
    overflow-y: auto;
    outline: 0;
  }
  &__init {
    z-index: 100;
    background: $color-white;
    @include centerAbsolute;
    max-width: 800px;
  }
  &__header {
    position: relative;
  }
  &__closeButton {
    position: absolute;
    top: 0;
    right: 0;
    font-size: 1.4rem;
    font-weight: 700;
    padding: 0.3rem;
    line-height: 1;
    color: $color-black;
    opacity: 0.3;
    cursor: pointer;
    border: none;
    &:hover {
      opacity: 0.7;
    }
  }
  &__text {
    padding: 40px;
    font-size: 32px;
    line-height: 1.5;
    text-align: center;
    font-family: "Roboto Slab", serif;
  }
  button {
    font-size: 0.9rem;
    font-weight: 700;
    border: none;
    border-radius: 3px;
    padding: 0.3rem 1rem;
    margin-left: 0.5rem;
  }
}

@media screen and (max-width: 768px) {
  .modal {
    &__init {
      width: 80%;
      max-width: 100%;
      max-height: 100%;
      padding: 0;
      position: relative;
      left: 43%;
    }

    &__text {
      padding: 20px;
    }
  }
}
