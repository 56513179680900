.contact {
  position: relative;

  &__header {
    padding: 0 0 40px 60px;
  }

  &__title {
    padding: 50px 60px;
    font-size: 3.5rem;
    font-family: "Reenie Beanie", cursive;
    text-transform: uppercase;
    letter-spacing: 2px;
  }

  &__subtitle {
    font-weight: 300;
    letter-spacing: 1px;
    max-width: 50%;
    font-family: "Hind", sans-serif;
  }

  &__bg {
    background-color: $button-light-color-bg;
    padding: 85px;
    position: absolute;
    width: 50%;
    z-index: 1;
    border-radius: 0 200px 0 0;
  }

  &__wrapper {
    padding: 0 20px 90px 60px;
    display: flex;
    justify-content: space-between;
    gap: 40px;
  }

  &__info {
    width: 100%;
    height: auto;
    flex: 1;
    &--list {
      display: grid;
      grid-template-columns: repeat(2, 1fr);

      gap: 20px;
      list-style: none;

      &-item {
        display: flex;
        align-items: center;
        font-size: 1.3rem;
        padding: 10px 0;
        font-weight: 300;
        font-family: "Roboto Slab", serif;
        color: $color-black;
        span {
          margin-right: 5px;
          position: relative;
          top: 2px;
        }
      }
    }
  }

  &__form {
    position: relative;
    flex: 1;
    z-index: 10;
    &--input {
      width: 100%;
      margin-bottom: 20px;
      text-emphasis: center;
      input,
      textarea {
        color: black;
        padding: 15px;
        width: 100%;
        border: none;
        box-shadow: 7px 8px 10px -10px $color-black;
        font-family: "Hind", sans-serif;
      }
      button {
        margin-top: 20px;
        min-width: 250px;
      }
    }
  }
}

@media only screen and (max-width: 550px) {
  .contact {
    &__subtitle {
      max-width: 90%;
    }

    &__form {
      &--input {
        width: 90%;
      }
    }

    &__info {
      &--list {
        grid-template-columns: 1fr;
      }
    }

    &__wrapper {
      flex-direction: column-reverse;
    }

    &__title {
      font-size: 2.5rem;
    }
  }
}
