.dark-mode-toggle {
  display: flex;
  margin: 0 auto;
  position: fixed;

  top: 10px;
  right: 20px;
  z-index: 10000117;

  & > button {
    color: $color-white;
    background-color: $toggle-dark-color;
    cursor: pointer;
    transition: color 0.3s ease;
    width: 30px;
    height: 30px;
    border: none;
    border-radius: 100%;
    transition: all 0.2s;
    &:last-child {
      color: $color-white;
      background-color: $color-black;
    }
    &:focus {
      outline: none;
    }

    &:hover {
      transform: scale(1.2);
    }
  }
}

.dmcheck {
  display: none;
}

@media screen and (max-width: 768px) {
  .dark-mode-toggle {
    top: 240px;
    right: 10px;
  }
}
