.projects {
  &__tabs {
    padding: 0 60px;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 40px;
  }
  &__title {
    padding: 80px 60px;
    font-size: 3.5rem;
    font-family: "Reenie Beanie", cursive;
    text-transform: uppercase;
    letter-spacing: 2px;
    z-index: 10;
    position: relative;
  }
}

@media screen and (max-width: 768px) {
  .projects {
    &__tabs {
      padding: 0 50px;
    }

    &__title {
      padding: 60px 50px;
      font-size: 2.5rem;
    }
  }
}

@media screen and (max-width: 480px) {
  .projects {
    &__tabs {
      grid-template-columns: 1fr;
    }
  }
}
