.navigation {
  padding: 0 20px;
  position: fixed;
  z-index: 10000;
  min-height: 56px;
  width: 100%;
  border-bottom: 2px solid $border-light;
  &__mobile {
    display: none;
  }

  &__desktop {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  &__list {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    max-width: 1200px;
    margin: 0 auto;
    // padding: 0 20px;
    list-style: none;
  }

  &__item {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 100%;
  }

  &__link {
    font-size: 20px;
    text-transform: uppercase;
    font-family: "Roboto Slab", serif;
    text-decoration: none;
    color: $color-black;
    letter-spacing: 2px;
    transition: all 0.4s cubic-bezier(0, 0.9, 0.3, 1.2);
    &:hover,
    &:active {
      color: $secondary-color;
      letter-spacing: 5px;
      cursor: pointer;
    }
  }

  &__logo {
    position: relative;
    width: 60px;
    padding: 5px;
    z-index: 10000117;
    transition: opacity ease-in-out;
    &:hover {
      opacity: 0.5;
    }
  }
}

@media only screen and (max-width: 768px) {
  .navigation {
    &__desktop {
      display: none;
    }

    &__mobile {
      display: inline-block;

      &__wrap {
        display: flex;
        justify-content: space-between;
        align-items: center;
        border-bottom: 2px solid $color-black;
      }
    }

    &__mobileList {
      height: 100vh;
      width: 100%;
      position: fixed;
      top: 57px;
      left: -40px;
      overflow-x: hidden;
      transition: 0.5s;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      gap: 40px;
      list-style-type: none;
    }

    &__hamburger {
      position: absolute;
      z-index: 10000111;
      top: 12px;
      right: 10px;
      display: inline-block;
      width: 30px;
      height: 30px;
      cursor: pointer;
      transition: all 0.2s ease-in-out;
      border: none;
      background: none;
      &:focus {
        outline: none;
      }
    }

    &__toggle {
      width: 100%;
      height: 100%;
      display: block;
      transition: all 0.2s ease-in-out;

      line:nth-of-type(1),
      line:nth-of-type(2),
      line:nth-of-type(3) {
        transition: all 0.2s ease-in-out;
      }
    }

    &__toggleOpen {
      transition: all 0.2s ease-in-out;
      line:nth-of-type(1) {
        transform: translateY(25%) rotate(45deg);
        transform-origin: 45% 20%;
      }

      line:nth-of-type(2) {
        opacity: 0;
      }

      line:nth-of-type(3) {
        transform: translateY(-25%) rotate(-45deg);
        transform-origin: 45% 80%;
      }
    }
  }
}
