@mixin centerAbsolute {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

@mixin boxShadow {
  box-shadow: 10px 12px 16px rgba(0, 0, 0, 0.4);
}

@mixin cardShadow {
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.4);
}

@mixin cardShadowLight {
  box-shadow: 0 0 5px rgba(255, 255, 255, 0.4);
}
