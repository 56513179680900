.hero {
  background-color: $color-white;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  padding: 20px;
  position: relative;

  &__info {
    padding: 40px;
    margin-top: 80px;
    width: 100%;
    color: $color-black;
    font-family: "Telex", sans-serif;
    position: relative;
    display: grid;
    grid-template-columns: 3fr 1fr;
    grid-gap: 20px;
    align-items: center;
    min-height: 200px;

    h1 {
      font-size: 3rem;
      font-weight: 700;
      font-family: "Roboto Slab", serif;
    }
  }

  &__resumeBtn {
    width: 100%;
  }

  &__scrollDown {
    position: absolute;
    bottom: 60px;
    left: 10px;
    font-size: 28px;
    letter-spacing: 2px;
    font-family: "Reenie Beanie", cursive;
    transform: rotate(90deg);
    transition: all 0.5s ease-in-out;
    color: $color-black;
  }
}

.animate-pop-in {
  animation: pop-in 0.9s cubic-bezier(0, 0.9, 0.3, 1.2) forwards;
  opacity: 0;
}

@keyframes pop-in {
  0% {
    opacity: 0;
    transform: translateY(-6rem) scale(0.8);
  }
  100% {
    opacity: 1;
    transform: none;
  }
}

@media only screen and (max-width: 768px) {
  .hero {
    &__info {
      grid-template-columns: 1fr;
      grid-gap: 40px;
      margin-bottom: 40px;
      h1 {
        font-size: 2rem;
      }
    }
  }
}

@media only screen and (max-width: 480px) {
  .hero {
    &__info {
      margin-top: 40px;
      h1 {
        min-height: 80px;
        font-size: 1.7rem;
      }
    }

    &__scrollDown {
      left: 0px;
    }
  }
}
