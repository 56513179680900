body.light-mode {
  background-color: $theme-bg-light;
  .navigation {
    background-color: $nav-light-color;
    &__logo {
      content: url(../assets/logo-for-white.svg);
    }

    g {
      stroke: $color-black;
      transition: all 0.2s ease-in-out;
    }

    &__mobileList {
      background-color: $nav-light-color;
      color: $color-black;
    }

    &__link {
      &:hover,
      &:active {
        color: $button-light-color;
      }
    }
  }

  .socialMedia {
    background-color: $nav-light-color;
    &__link {
      color: $color-black;
      &:hover {
        color: $button-light-color;
      }
    }
  }

  .hero {
    background-color: $theme-bg-light;
    &__info {
      color: $color-black;
    }
    &__btn {
      border: 1px solid $color-black;
    }
  }

  .skills {
    background-color: $color-white;
    color: $color-black;
  }

  .card {
    .back {
      color: $color-black;
      h4 {
        color: $color-white;
      }
    }

    h3 {
      background-color: $button-light-color;
      color: $color-white;
    }
    h4 {
      color: $color-black;
      background-color: $button-light-color;
    }
    button {
      color: $color-black;
      border: 1px solid $color-black;
    }

    p {
      color: $card-dark-bg;
    }

    .responsibilities {
      color: $color-black;
    }
  }

  .graphics {
    color: $color-black;
    &__buttonContainer {
      .mas {
        background-color: $button-light-color;
      }
    }

    &__deckEmpty {
      border: 50px solid $nav-light-color;
    }
  }

  .footer {
    background-color: $nav-light-color;
    border-top: 2px solid $border-light;
    &__copyright {
      color: $color-black;
    }
  }

  .button {
    color: $button-light-color;
    background: $button-light-color-bg;
    &:hover {
      background: $button-light-color;
      span {
        color: $color-white;
      }
    }
  }
}

body.dark-mode {
  background-color: $theme-bg-dark;
  .navigation {
    background-color: $nav-dark-color;
    border-bottom: 2px solid $border-dark;
    &__logo {
      content: url(../assets/logo-for-black.svg);
    }

    g {
      stroke: $color-white;
      transition: all 0.2s ease-in-out;
    }

    &__mobileList {
      background-color: $nav-dark-color;
      color: $color-white;
    }

    &__link {
      color: $color-white;

      &:hover,
      &:active {
        color: $toggle-dark-color;
      }
    }
  }

  .socialMedia {
    background-color: $nav-dark-color;
    border-left: 2px solid $border-dark;
    &__link {
      color: $color-white;
      &:hover {
        color: $toggle-dark-color;
      }
    }
  }

  .hero {
    background-color: $theme-bg-dark;
    &__info {
      color: $color-white;
    }
    &__scrollDown {
      color: $color-white;
    }
    &__btn {
      color: $color-white;
      &:hover {
        background-color: $primary-color;
      }
    }
  }
  .about {
    &__title {
      color: $color-white;
    }
    &__bio {
      &--backBorder {
        border-top: 35px solid $button-dark-color-bg;
      }
      &--info {
        background-color: $nav-dark-color;
        color: $primary-color-white-light;
        a {
          color: $button-dark-color;
          &:hover {
            color: $color-white;
            cursor: pointer;
          }
        }
      }
    }
  }
  .skills {
    background-color: $color-black;
    color: $color-white;
    border: none;
  }
  .card {
    .back {
      color: $color-white;
    }
    h4 {
      color: $color-black;
    }
    button {
      color: $color-white;
      &:hover {
        background-color: $primary-color;
      }
    }
    .front {
      background-color: $nav-dark-color;
      color: $color-white;
    }
  }

  .projects {
    &__title {
      color: $color-black;
    }
  }
  .graphics {
    color: $color-white;

    &__title {
      color: $color-black;
    }
  }

  .contact {
    color: $color-white;

    &__title {
      color: $color-black;
    }
    &__bg {
      background-color: $button-dark-color-bg;
    }

    &__info {
      &--list {
        &-item {
          color: $color-white;
        }
      }
    }

    &__form {
      &--input {
        input,
        textarea {
          box-shadow: 2px 2px 3px $primary-color-white-light;
        }
      }
    }
  }

  .modal {
    background-color: $color-white;
    &__init {
      background: $color-black-light;
    }

    &__text {
      color: $color-white;
    }

    &__closeButton {
      opacity: 0.1;
      &:hover {
        opacity: 0.4;
      }
    }
  }

  .footer {
    background-color: $nav-dark-color;
    border-top: 2px solid $border-dark;
  }

  .button {
    &__text {
      color: $color-white;
    }
  }
}
