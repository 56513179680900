@mixin button($bcolor, $url, $x1, $y1, $bor, $col) {
  background: $bcolor;
  -webkit-mask: url($url);
  mask: url($url);
  -webkit-mask-size: $x1 $y1;
  mask-size: $x1 $y1;
  border: $bor;
  color: $col;
}

.graphics {
  &__title {
    font-size: 3.5rem;
    padding: 80px 60px;
    font-family: "Reenie Beanie", cursive;
    text-transform: uppercase;
    letter-spacing: 2px;
    z-index: 1100000;
    position: relative;
    flex: 1;
  }

  &__buttonContainer {
    position: relative;
    width: 300px;
    height: 70px;
    margin-left: auto;
    margin-right: auto;
    overflow: hidden;
    font-weight: 300;
    transition: 0.5s;
    flex: 1;

    .mas {
      @include centerAbsolute;
      display: flex;
      justify-content: center;
      align-items: center;
      width: 300px;
      height: 70px;
      color: #000;
      background-color: $button-dark-color-bg;
      text-align: center;
      font-weight: 300;
      font-size: 24px;
      overflow: hidden;
      font-weight: bold;
      font-family: "Hind", sans-serif;
      text-transform: uppercase;
      letter-spacing: 1px;
    }

    button {
      width: 300px;
      height: 100%;
      font-weight: 300;
      font-size: 24px;
      letter-spacing: 1px;
      font-weight: bold;
      font-family: "Hind", sans-serif;
      text-transform: uppercase;

      @include button(
        $shuffle-btn-bg-dark,
        "https://raw.githubusercontent.com/robin-dela/css-mask-animation/master/img/urban-sprite.png",
        3000%,
        100%,
        none,
        rgb(1, 1, 1)
      );
      cursor: pointer;
      -webkit-animation: ani2 0.7s steps(29) forwards;
      animation: ani2 0.7s steps(29) forwards;

      &:hover {
        -webkit-animation: ani 0.7s steps(29) forwards;
        animation: ani 0.7s steps(29) forwards;
      }
    }
  }

  &__deck {
    min-height: 500px;
    position: relative;
    margin: 100px auto;
  }

  &__deckEmpty {
    min-height: 500px;
    max-width: 500px;
    margin: 100px auto;
    display: flex;
    align-items: center;
    justify-content: center;
    font-family: "Roboto Slab", serif;
    position: relative;
    border-radius: 50%;
    border: 50px solid $nav-dark-color;
    @include boxShadow;

    &:hover {
      .star-five {
        -webkit-animation: spin 1s linear infinite;
        animation: spin 1s linear infinite;
      }
    }

    h2 {
      @include centerAbsolute;
      font-size: 35px;
      width: 100%;
      padding: 20px;
      font-weight: 300;
      text-align: center;
      letter-spacing: 3px;
      font-weight: bold;
    }
    .star-five {
      margin: 3.125em 0;
      position: relative;
      display: block;
      width: 0em;
      height: 0em;
      border-right: 6.25em solid transparent;
      border-bottom: 4.3em solid $button-dark-color;
      border-left: 6.25em solid transparent;
      transform: rotate(35deg);
    }
    .star-five:before {
      border-bottom: 5em solid $button-dark-color;
      border-left: 2em solid transparent;
      border-right: 1.875em solid transparent;
      position: absolute;
      height: 0;
      width: 0;
      top: -45px;
      left: -65px;
      display: block;
      content: "";
      transform: rotate(-35deg);
    }
    .star-five:after {
      position: absolute;
      display: block;
      top: 3px;
      left: -105px;
      width: 0;
      height: 0;
      border-right: 6.25em solid transparent;
      border-bottom: 4.3em solid $button-dark-color;
      border-left: 5.95em solid transparent;
      transform: rotate(-70deg);
      content: "";
    }
  }

  &__card {
    position: absolute;
    margin: auto;
    left: 0;
    right: 0;
    max-width: 500px;
    object-fit: cover;
    background: $button-dark-color-bg;
    border-radius: 10px;
    padding: 10px;
  }
}

-webkit-keyframes ani {
  from {
    -webkit-mask-position: 0 0;
    mask-position: 0 0;
  }

  to {
    -webkit-mask-position: 100% 0;
    mask-position: 100% 0;
  }
}

@keyframes ani {
  from {
    -webkit-mask-position: 0 0;
    mask-position: 0 0;
  }

  to {
    -webkit-mask-position: 100% 0;
    mask-position: 100% 0;
  }
}

@-webkit-keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@media screen and (max-width: 550px) {
  .graphics {
    &__deck {
      margin-top: 100px;
    }

    &__title {
      font-size: 2.5rem;
    }

    &__deckEmpty {
      max-width: 300px;
      margin: 50px auto;
      border-radius: 0%;
    }

    &__card {
      max-width: 270px;
    }
  }
}
