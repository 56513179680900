.skill {
  margin: 10px;
  text-align: center;
  display: inline-block;
  align-items: center;
  justify-content: center;

  &__name {
    font-size: 15px;
    font-family: 'ZCOOL XiaoWei', serif;
  }
}
