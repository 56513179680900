.about {
  padding-left: 60px;
  &__bio {
    display: flex;
    justify-content: flex-end;

    position: relative;

    &--shopifyLink {
      color: $primary-color;
      text-decoration: none;
    }

    &--backBorder {
      max-width: 900px;
      border-top: 35px solid $button-light-color-bg;
    }

    &--info {
      @include boxShadow;
      margin: auto;
      padding: 40px;
      width: 100%;
      font-family: "Hind", sans-serif;
      font-weight: 300;
      font-size: 18px;
      line-height: 28px;
      letter-spacing: 1px;
      background-color: $nav-light-color;
      color: $color-black;
      a {
        text-decoration: none;
        color: $button-light-color-bg;

        &:hover {
          color: inherit;
          cursor: pointer;
        }
      }
    }
  }
}

@media only screen and (max-width: 550px) {
  .about {
    &__bio {
      &--stack {
        padding: 120px;
        margin-left: 10px;
      }
      &--shopify {
        right: 50px;
      }
      &--storetasker {
        transform: scale(0.3);
        &:hover {
          transform: scale(0.5) rotate(-25deg);
        }
      }
      &--info {
        font-size: 16px;
        line-height: 24px;
        padding: 20px;
      }
    }
  }
}
