* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

.hidden {
  display: none;
}

.content {
  transform: translateX(-30px);
}
