.card {
  width: 100%;
  height: 520px;
  position: relative;
  display: inline-block;
  align-items: center;
  justify-content: center;
  @include cardShadow;
  .front,
  .back {
    display: block;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    width: 100%;
    height: 520px;
    position: absolute;
    overflow-y: auto;
  }

  img {
    max-height: 150px;
    width: 100%;
    object-fit: cover;
  }
  .front {
    background: $nav-light-color;
    transition: all 0.5s cubic-bezier(0, 0.9, 0.3, 1.2);
    z-index: 20;
    color: $color-black;
    h3 {
      text-align: right;
      font-size: 2rem;
      color: $color-black;
      margin-top: -1.5em;
      background-color: $toggle-dark-color;
      position: relative;
      padding: 10px;
      margin-bottom: 0.5em;
      font-family: "Roboto Slab", serif;
      font-weight: 600;
    }

    p {
      padding: 0 10px;
      font-family: "Hind", sans-serif;
      font-size: 18px;
      color: $card-light-bg;
      text-decoration: underline;
    }

    .responsibilities {
      padding: 15px 10px 0 10px;
      margin-left: 20px;
      font-family: "Hind", sans-serif;
      font-weight: 300;
      letter-spacing: 1px;
      list-style-type: square;
      li {
        font-size: 15px;
        line-height: 1.5;
        margin-bottom: 5px;
        font-weight: 300;

        p {
          font-size: 15px;
          line-height: 1.5;
          margin-bottom: 5px;
          font-weight: 300;
        }
      }
    }
  }
  .back {
    background: inherit;
    color: $color-black;
    z-index: 1;
    padding-top: 180px;
    h4 {
      font-size: 2rem;
      text-align: right;
      background-color: $toggle-dark-color;
      padding: 10px;
      margin-top: 1em;
      margin-bottom: 1rem;
      font-family: "Roboto Slab", serif;
      font-weight: 500;
    }
    p {
      padding: 0 10px;
      text-transform: uppercase;
      font-family: "Hind", sans-serif;
      font-weight: 600;
      font-size: 16px;
      letter-spacing: 2px;
    }
  }

  .cta {
    position: absolute;
    bottom: 40px;

    right: 20px;
  }
  &:hover {
    .front {
      top: -300px;
      transform: rotate(2deg);
    }
  }
}

@media screen and (max-width: 768px) {
  .card {
    height: 650px;
    .front {
      height: 650px;
      h3 {
        font-size: 1.5rem;
      }
    }
    .back {
      height: 650px;
      padding-top: 330px;
      h4 {
        font-size: 1.5rem;
      }
    }
  }
}
