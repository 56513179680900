$primary-color: #6c5d5d;
$primary-color-light: rgb(129, 159, 216);
$primary-color-dark: rgb(14, 0, 68);
$primary-color-red-light: rgb(238, 82, 82);
$primary-color-brown: #802f00;
$primary-color-white-light: rgb(233, 229, 229);
$secondary-color: #2e3452;
$secondary-color-light: #2a3245;
$color-black: #000;
$color-black-light: #181818;
$color-white: #ffff;
$nav-dark-color: #202722;
$nav-light-color: #cfc9ca;
$toggle-dark-color: #f8960d;
$nav-link-light-hover: #f4f1ee;
$button-dark-color: #f8960d;
$button-light-color: #0b843f;
$button-dark-color-bg: #f8960d31;
$button-light-color-bg: #0b843fa3;
$shuffle-btn-bg-dark: #f8960d;
$shuffle-btn-bg-light: #0b843f53;
$card-dark-bg: #16321e;
$card-light-bg: #99abaf;
$theme-bg-light: #5e5c5b;
$theme-bg-dark: #181818;
$border-dark: #424b45;
$border-light: #b9afaf;
