.footer {
  color: $color-white;
  display: flex;
  position: fixed;
  justify-content: space-around;
  width: 100%;
  z-index: 1001;
  &__copyright {
    padding: 20px;
    font-size: 16px;
    letter-spacing: 3px;
    font-family: "Roboto Slab", serif;
  }
}
