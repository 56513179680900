.socialMedia {
  position: fixed;
  min-height: 100vh;
  border-left: 2px solid $border-light;
  right: 0px;
  top: 56px;
  z-index: 100001;
  &__link {
    display: block;
    text-decoration: none;
    color: $color-black;
    padding: 15px;
    position: relative;
    transition: all 0.4s cubic-bezier(0, 0.9, 0.3, 1.2);
    &:hover {
      color: $secondary-color;
      transform: rotate(25deg) scale(1.5);
    }
  }

  &__icon {
    width: 25px;
    height: 25px;
    margin: 3px;
  }
}

@media only screen and (max-width: 768px) {
  .socialMedia {
    top: 57px;
    &__link {
      padding: 10px;
    }
  }
}
